import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import Slide from '@material-ui/core/Slide';
import Title from "../Title";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const [qrCode, setQrCode] = useState("");



	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	  });
	useEffect(() => {
		if (!whatsAppId) return;
		const socket = openSocket();

		socket.on("whatsappSession", data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [whatsAppId, onClose]);

	const handleClose = () => {
		onClose()
	  };
	return (
		<Dialog fullScreen open={open} onClose={onClose} scroll="paper">
			<DialogContent style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
				<Paper style={{textAlign:"center"}} elevation={0} >
					{/* <Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography> */}
					{qrCode ? (
						<QRCode value={qrCode} size={256} />
					) : (
						<span>Waiting for QR Code</span>
					)}
					<Title>Para usar whatsapp en ChatUp necesitas:</Title>
					
						<div>Abre el whatsapp en tu teléfono</div>
						<div>Toca o abre el menu de configuración, y selecciona whatsapp Web</div>
						<div>Cuando active la camara apunta a la pantalla hasta que se cierre la visualización de la camara.</div>
					
					<div  onClick={handleClose}  style={{cursor:"pointer", color: "#6E00FF", fontFamily: 'Plus Jakarta Sans', size:"10px", paddingTop: "15px"  }}>Cerrar</div>
						

				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
