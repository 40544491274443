import { Configuration, OpenAIApi } from "openai";

const openai = new OpenAIApi(new Configuration({ apiKey: "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" }));


async function chatWithGPT(userInput, headerSystem, conversation = []) {
  try {
    if (conversation.length === 0) {
      // Agregar el mensaje de sistema si la conversación está vacía
      conversation.push({
        role: "system",
        content: headerSystem || 'Mejora el texto',
      });
    }

    conversation.push({ role: "user", content: userInput });

    const res = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-0125",
      messages: conversation,
    });

    const responseMessage = res.data.choices[0].message.content;
    conversation.push({ role: "assistant", content: responseMessage });

    return { response: responseMessage, conversation };
  } catch (error) {
    // Aquí puedes manejar el error como desees, por ejemplo, registrarlo o devolver un mensaje de error personalizado.
    console.error("Ocurrió un error:", error);
    return { error: "Ocurrió un error inesperado" };
  }
}

export default chatWithGPT;
