import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, FormControlLabel, MenuItem, Select, Switch, Backdrop, CircularProgress, Container } from '@mui/material';
import { Grid } from '@material-ui/core';
import { AuthContext } from "../../context/Auth/AuthContext";
import { useEffect } from 'react';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { toast } from "react-toastify";
import { i18n } from '../../translate/i18n';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import MainContainer from '../../components/MainContainer';
import moment from 'moment';
import ReactiveButton from 'reactive-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faCircleNotch, faBomb, faCircleCheck } from '@fortawesome/free-solid-svg-icons';


function ScheduleAttention() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [openTime, setOpenTime] = useState('00:00');
  const [closeTime, setCloseTime] = useState('00:00');
  const [bodyText, setBodyText] = useState('');
  const [enable, setEnable] = useState(false);
  const [withIA, setwithIA] = useState(false);
  const { user } = useContext(AuthContext);
  const [selectedQueue, setSelectedQueue] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatOutsideHours, setChatOutsideHours] = useState(false);
  const [stateSubmit, setStateSubmit] = useState('idle');
  const [selectedMethod, setSelectedMethod] = useState('message');
  const [botList, setBotlist] = useState([])
  const [selectedBotInSchedule, setSelectedBotInSchedule] = useState();
  const [selectedBotOutSchedule, setSelectedBotOutSchedule] = useState();

  const handleChangeInSchedule = (event) => {
    setSelectedBotInSchedule(event.target.value);
  };

  const handleChangeOutSchedule = (event) => {
    setSelectedBotOutSchedule(event.target.value);
  };

  const [horarios, setHorarios] = useState({
    lunes: { open: '00:00', close: '00:00' },
    martes: { open: '00:00', close: '00:00' },
    miercoles: { open: '00:00', close: '00:00' },
    jueves: { open: '00:00', close: '00:00' },
    viernes: { open: '00:00', close: '00:00' },
    sabado: { open: '00:00', close: '00:00' },
    domingo: { open: '00:00', close: '00:00' },
  });

  const handleTimeClick = (event, day) => {
    const selectedDayHours = horarios[day];
    setOpenTime(selectedDayHours.open || '00:00');
    setCloseTime(selectedDayHours.close || '00:00');
    setAnchorEl(event.currentTarget);
    setSelectedDay(day);
  };

  const handleTimeClose = () => {
    setAnchorEl(null);
  };

  const handleTimeSelect = () => {
    setHorarios({
      ...horarios,
      [selectedDay]: { open: openTime, close: closeTime },
    });
    setAnchorEl(null);
  };

  const handleSwitchChange = () => {
    setEnable(!enable);
  };

  const handleChatOutsideHoursChange = () => {
    setChatOutsideHours(!chatOutsideHours);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setStateSubmit('loading');
      const dataToSend = {
        queueId: selectedQueue,
        enable,
        withIA: selectedMethod === "ia",
        withBot: selectedMethod === "bot",
        botInHours: selectedBotInSchedule,
        botOutHours: selectedBotOutSchedule,
        chatOutsideHours,
        bodyText,
        mondayOpen: horarios.lunes.open,
        mondayClose: horarios.lunes.close,
        tuesdayOpen: horarios.martes.open,
        tuesdayClose: horarios.martes.close,
        wednesdayOpen: horarios.miercoles.open,
        wednesdayClose: horarios.miercoles.close,
        thursdayOpen: horarios.jueves.open,
        thursdayClose: horarios.jueves.close,
        fridayOpen: horarios.viernes.open,
        fridayClose: horarios.viernes.close,
        saturdayOpen: horarios.sabado.open,
        saturdayClose: horarios.sabado.close,
        sundayOpen: horarios.domingo.open,
        sundayClose: horarios.domingo.close,
      };

      const response = await api.post('/scheduleattention', dataToSend);
      toast.success(i18n.t("scheduleAttention.success"));
      setStateSubmit('success')
    } catch (error) {
      toast.error(i18n.t("scheduleAttention.error"));
      setStateSubmit('error')
      console.error('Error al crear el registro:', error);
    } finally {
      setLoading(false); // Desactivar el Backdrop, independientemente del resultado de la llamada
    }
  };


  const handleQueueChange = (event) => {
    setSelectedQueue(event.target.value);
  };

  const handleMethod = (event) => {
    setSelectedMethod(event.target.value);
  };

  const BodyMessage = () => {
    const renderLabel = () => {
      switch (selectedMethod) {
        case 'ia':
          return 'Contexto para ChatGPT';
        default:
          return 'Mensaje fuera del horario establecido';
      }
    };

    const renderBotFields = () => {
      if (selectedMethod === 'bot') {
        return (
          <>
            <TextField
              select
              labelId="type-simple-select-label"
              id="inschedule-simple-select"
              label="Bot para horario de atención"
              size='small'
              value={selectedBotInSchedule}
              onChange={handleChangeInSchedule}
              fullWidth

              sx={{ mt: 2 }}
            >
              <MenuItem key='null' value={null}>
                - Ninguno -
              </MenuItem>
              {botList.map((bot) => (
                <MenuItem key={bot.id} value={bot.id}>
                  🤖 {bot.name}
                </MenuItem>
              )
              )}
            </TextField>

            <TextField
              select
              labelId="type-simple-select-label"
              id="outschedule-simple-select"
              label="Bot que responde fuera de horario de atención"
              size='small'
              value={selectedBotOutSchedule}
              onChange={handleChangeOutSchedule}
              fullWidth
              sx={{ mt: 2 }}
            >
              <MenuItem key='null' value={null}>
                - Ninguno -
              </MenuItem>
              {botList.map((bot) => (
                <MenuItem key={bot.id} value={bot.id}>
                  🤖 {bot.name}
                </MenuItem>
              )
              )}
            </TextField>
          </>
        );
      } else {
        return (
          <>
            <TextField
              id="outlined-multiline-static"
              label={renderLabel()}
              multiline
              rows={7}
              value={bodyText}
              fullWidth
              onChange={(e) => setBodyText(e.target.value)}
              sx={{ mt: 4 }}
            />
          </>);

      }
    };

    return renderBotFields();
  };


  useEffect(() => {
    if (!selectedQueue) {
      setSelectedQueue(user?.queues[0].id)
    }
  }, [user])

  useEffect(async () => {
    if (selectedQueue) {
      try {
        setLoading(true);
        const { data } = await api.get(`/scheduleattention/${selectedQueue}`);
        if (data) {
          const { data: resUser } = await api.get("/users/", {
            params: { companyId: user.companyId, profile: 'bot' },
          });
          if (resUser) {
            setBotlist(resUser.users)
          }
          setSelectedQueue(data.queueId);
          setEnable(data.enable);
          setSelectedMethod(data?.withIA ? 'ia' : (data?.withBot ? 'bot' : 'message'));
          setSelectedBotInSchedule(data.botInHours)
          setSelectedBotOutSchedule(data.botOutHours)
          setChatOutsideHours(data.chatOutsideHours);
          setBodyText(data.bodyText);
          setHorarios({
            lunes: { open: data.mondayOpen, close: data.mondayClose },
            martes: { open: data.tuesdayOpen, close: data.tuesdayClose },
            miercoles: { open: data.wednesdayOpen, close: data.wednesdayClose },
            jueves: { open: data.thursdayOpen, close: data.thursdayClose },
            viernes: { open: data.fridayOpen, close: data.fridayClose },
            sabado: { open: data.saturdayOpen, close: data.saturdayClose },
            domingo: { open: data.sundayOpen, close: data.sundayClose },
          });
        }

      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    }
  }, [selectedQueue])


  return (
    <MainContainer>
      {loading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

      <form onSubmit={handleSubmit}>
        <Container >
          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            {/* columna 1 */}
            <Grid item xs={5} style={{ paddingRight: 20 }}>
              <Grid item >
                <TextField
                  select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Seleccione área"
                  size='small'
                  value={selectedQueue}
                  fullWidth
                  onChange={handleQueueChange}
                >
                  {
                    user?.queues?.map(queue => (
                      <MenuItem key={queue.id} value={queue.id}>
                        {queue.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    value="bottom"
                    control={<Switch onChange={handleSwitchChange} checked={enable} />}
                    label={
                      <Typography variant="body2" style={{ fontSize: '0.6rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        Activar horario
                      </Typography>
                    }
                    labelPlacement="bottom"
                    size="small"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  />
                </Grid>
                {enable &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel
                        control={<Switch onChange={handleChatOutsideHoursChange} checked={chatOutsideHours} />}
                        label={
                          <Typography variant="body2"
                            style={{
                              fontSize: '0.6rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              marginLeft: 0
                            }}>
                            Archivar chats fuera de horario
                          </Typography>
                        }
                        labelPlacement="bottom"
                      />
                    </Grid>
                    <TextField
                      select
                      labelId="type-simple-select-label"
                      id="demo-simple-select"
                      label="Método de respuesta"
                      size='small'
                      value={selectedMethod}
                      fullWidth
                      onChange={handleMethod}
                      sx={{ mt: 2 }}
                    >
                      <MenuItem key='bot' value='bot'>
                        Bot
                      </MenuItem>
                      <MenuItem key='message' value='message'>
                        Mensaje definido
                      </MenuItem>
                      <MenuItem key='ia' value='ia'>
                        Inteligencia Artificial chatGTP
                      </MenuItem>

                    </TextField>
                    <BodyMessage />
                  </>
                }
              </Grid>


            </Grid>
            {/* columna 2 */}
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              {enable && (
                <Box >
                  <Grid container spacing={2}>

                    {Object.keys(horarios).map((day) => (
                      <React.Fragment key={day}>
                        <Grid key={day} item xs={2}>
                          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            {day}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            size='small'
                            label={
                              horarios[day].open === horarios[day].close ?
                                'No atiende' : `Apertura`
                            }
                            value={moment(horarios[day].open, 'HH:mm:ss').format('HH:mm')}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} >
                          <TextField
                            size='small'
                            label={
                              horarios[day].open === horarios[day].close ?
                                'No atiende' : `Cierre`
                            }
                            value={moment(horarios[day].close, 'HH:mm:ss').format('HH:mm')}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={(event) => handleTimeClick(event, day)}
                            color="primary"
                            size="small"
                          >
                            <AccessTimeIcon />
                          </IconButton>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
              )}
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleTimeClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div style={{ padding: '16px' }}>
                  <Typography variant="h6">Seleccionar horario para {selectedDay}</Typography>
                  <Box sx={{ pt: 2, pb: 2, display: 'flex', gap: 1 }}>
                    <TextField
                      size='small'
                      label="Apertura"
                      type="time"
                      value={openTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setOpenTime(e.target.value)}
                    />
                    <TextField
                      size='small'
                      label="Cierre"
                      type="time"
                      value={closeTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setCloseTime(e.target.value)}
                    />
                    <Button
                      disabled={!openTime || !closeTime}
                      onClick={handleTimeSelect}
                      variant="contained"
                      color="primary"
                    >
                      Seleccionar
                    </Button>
                  </Box>
                </div>
              </Popover>
            </Grid>
          </Grid>
        </Container>
        <MainHeader>
          <MainHeaderButtonsWrapper>
            <ReactiveButton
              buttonState={stateSubmit}
              color={'violet'}
              idleText={i18n.t("scheduleAttention.button.save")}
              loadingText={
                <>
                  <FontAwesomeIcon icon={faCircleNotch} spin /> Loading
                </>
              }
              successText={
                <>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  {i18n.t("scheduleAttention.success")}
                </>
              }
              errorText={
                <>
                  <FontAwesomeIcon icon={faBomb} />
                  {i18n.t("scheduleAttention.error")}
                </>
              }
              type={'submit'}
              style={{
                borderRadius: '5px',
              }}
              outline={false}
              shadow={false}
              rounded={false}
              size={'normal'}
              block={false}
              messageDuration={2000}
              disabled={bodyText.length === 0}
              animation={true}
            />
          </MainHeaderButtonsWrapper>
        </MainHeader>
      </form>

    </MainContainer>
  );
}

export default ScheduleAttention;
