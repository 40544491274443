import React, { useState, useContext, useCallback } from "react";
import { TextField, Button, IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import './login.css';
import Stack from '@mui/material/Stack';
import { ReactComponent as LogoChatup2 } from '../../assets/login/logo2ChatupV1.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { handleLogin } = useContext(AuthContext);
  const [showIframeOnly, setShowIframeOnly] = useState(false); // Estado para mostrar solo el iframe

  const handleChangeInput = useCallback((e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }, [user]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    handleLogin(user);
  }, [handleLogin, user]);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Maneja el clic en el botón "Ver website"
  const handleShowIframeOnly = () => {
    setShowIframeOnly(true);
  };

  return (
    <div className="login-container">
      {!showIframeOnly && ( /* Mostrar el formulario de login solo si `showIframeOnly` es falso */
        <div className="login-form-container">
          <div className="login-form">
            <Stack
              direction="column"
              spacing={0}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LogoChatup2 width="80%" height="auto" />
              <div className="welcome">
                Clientes Chatup
              </div>
            </Stack>

            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label={i18n.t("login.form.email")}
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18n.t("login.form.password")}
                id="password"
                value={user.password}
                onChange={handleChangeInput}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {i18n.t("login.buttons.submit")}
              </Button>
            </form>
            <div className="view-website-button">
              <Button
                onClick={handleShowIframeOnly}
                variant="outlined"
                color="secondary"
                className="view-website-button"
              >
                Ver website
              </Button>
            </div>
            <p
              onClick={() => alert('Nos encontramos mejorando para nuestra primera versión LTS')}
              className="forgot-pass"
            >
              {new Date().getFullYear()} Perú - v1.9.3.24
            </p>
          </div>
        </div>
      )}
      <div className={`iframe-container ${showIframeOnly ? 'full-screen' : ''}`}>
        <iframe
          src={process.env.REACT_APP_WEB_DASHBOARD}
          title="Dashboard"
          className="dashboard-iframe"
        />
      </div>
    </div>
  );
};

export default Login;
