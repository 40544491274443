import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { AuthContext } from "../../context/Auth/AuthContext";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import TicketsQueueSelect from "../TicketsQueueSelect";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Chip, Stack, Dialog, InputAdornment, TextField } from "@mui/material";
import Search from "../Search";

export default function AreaCard({ showAllTickets, setShowAllTickets, showSearch, setShowSearch, showBotActive, setSelectedQueueIds, selectedQueueIds }) {
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSearch, setValueSearch] = React.useState('');
  const [soundNotification, setSoundNotification] = useState(100)
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSound = () => {
    if (user?.config?.notification?.sound > 0 || soundNotification > 0) {
      setSoundNotification(0)
      user.config = {
        notification: {
          sound: 0
        }
      }
    } else {
      setSoundNotification(100)
      user.config = {
        notification: {
          sound: 100
        }
      }
    }
  }

  const handleSearch = id => {
    const { value } = id.target;
    setValueSearch(value)
  };


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  return (
    <Card
      color="primary"
      size="sm"
      variant="soft"
      sx={{
        width: "100%",
        resize: 'horizontal',
        height: 'auto',
        backgroundImage: 'linear-gradient(340deg, #f4f9fbbd, #6e00ff1c)',
        borderRadius: '10px'

      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction="row">
          <Avatar src={`https://api.multiavatar.com/${user?.name}.png`} size="lg" />
          <Stack>
            <div>
              <Typography sx={{ mr: 'auto', pl: '8px', color: "#5A6A9D" }} level="title-lg">{user?.name}</Typography>
            </div>
            <div>
              {user.profile === "admin" &&
                <Tooltip title={showAllTickets ? "ADMIN: Visualizar solo mis chats asignados" : "ADMIN: Visualizar todos los chats"}>
                  <IconButton size='small' variant="plain" style={{ color: showAllTickets ? "green" : "#797D7F" }}>
                    <VisibilityIcon
                      onClick={setShowAllTickets}
                    />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title={soundNotification > 0 ? "Silenciar notificaciones" : "Activar sonido de notificaciones"}>
                <IconButton size='small' variant="plain" sx={{ mr: 'auto', color: soundNotification > 0 ? "green" : "#797D7F" }}>
                  {soundNotification > 0 ?
                    <VolumeUpIcon onClick={handleSound} />
                    :
                    <VolumeOffIcon onClick={handleSound} />
                  }
                </IconButton>
              </Tooltip>
            </div>
          </Stack>
        </Stack>
        <TicketsQueueSelect
          style={{ marginLeft: 6, border: 0 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
        />
      </Box>
      <Stack id="cardAction" direction="row" spacing={1} alignItems="center" >
        <TextField
          id="input-with-icon-textfield"
          fullWidth
          size="small"
          placeholder="Buscar contacto"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        {valueSearch !== '' &&
          <Chip color="info" label="Buscar" avatar={<SearchIcon style={{ color: "white" }} />} onClick={handleOpenDialog} />
        }
      </Stack>

      {/* Dialog para mostrar el componente de búsqueda */}
      <Dialog open={openDialog} onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '90vh',
            maxHeight: '70vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          },
        }}>
        <Search value={valueSearch} onClose={handleCloseDialog} />
      </Dialog>
    </Card>
  );
}