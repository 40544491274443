import React from "react";

import { Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  ticketHeader: {
    display: "flex",
    // backgroundColor: "#f0f2f5",
    flex: "none",
    // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "position":"absolute",
    "left":"0",
    "right":"0",
    "top":"0",
    "zIndex":"1",
    "backgroundColor":"rgba(255,255,255,.05)",
    "borderBottom":"1px solid #eaeaf1",
    "WebkitBackdropFilter":"blur(7px)",
    "backdropFilter":"blur(7px)",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
}));

const TicketHeader = ({ loading, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleBack = () => {
    history.push("/conversations");
  };

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card square className={classes.ticketHeader}>
          <Button color="primary" onClick={handleBack}>
            <ArrowBackIos />
          </Button>
          {children}
        </Card>
      )}
    </>
  );
};

export default TicketHeader;
