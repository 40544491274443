import React, { useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia
} from "@material-ui/core";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
} from "@material-ui/icons";
import Icon from '@material-ui/core/Icon';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
}));

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const Connections = () => {
  const classes = useStyles();

  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  const handleStartWhatsAppSession = async whatsAppId => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async whatsAppId => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

  const handleOpenQrModal = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, [setQrModalOpen, setSelectedWhatsApp]);

  const handleEditWhatsApp = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const renderActionButtons = whatsApp => {
    return (
      <>

        {(whatsApp.status === "DISCONNECTED" && user.profile === "admin") && (
          <>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ backgroundColor: "#F3B559" }}
              onClick={() => handleStartWhatsAppSession(whatsApp.id)}
            >
              {i18n.t("connections.buttons.tryAgain")}
            </Button>{" "}
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              style={{ backgroundColor: "#F3B559" }}
              onClick={() => handleRequestNewQrCode(whatsApp.id)}
            >
              {i18n.t("connections.buttons.newQr")}
            </Button>
          </>
        )}

        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled color="default">
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}
      </>
    );
  };

  const renderStatusToolTips = whatsApp => {
    return (
      <div style={{ color: "#6E00FF" }} className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={handleSubmitConfirmationModal}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>
      <QrcodeModal
        open={qrModalOpen}
        onClose={handleCloseQrModal}
        whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
      />
      <WhatsAppModal
        user={user}
        open={whatsAppModalOpen}
        onClose={handleCloseWhatsAppModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />

      <div elevation={0} className={classes.mainPaper} variant="outlined">
        <Grid style={{ padding: 24 }} container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
          {whatsApps?.length > 0 &&
            whatsApps.filter(data => data.companyId === user.companyId).map((whatsApp, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Card style={{ display: "flex", maxWidth: 340 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", }}>
                    <CardContent >
                      {/* Titulo */}
                      <Typography
                        style={{ display: "flex" }}
                        component="div"
                        variant="h5"
                      >
                        {whatsApp.name}
                        {whatsApp.isDefault && (
                          <CheckCircle style={{ color: green[500] }} />
                        )}
                      </Typography>

                      {/* Subtitulo */}
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        style={{ fontSize: 12 }}
                      >
                        Actualizado:{" "}
                        {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        style={{ fontSize: 12 }}
                      >
                        Número:{" "}
                        {whatsApp.number || 'Aun no asignado'}
                      </Typography>

                    </CardContent>

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        pl: 1,
                        pb: 1,
                        paddingLeft: 16
                      }}
                    >
                      {/* Boton eliminar */}
                      {user.profile === "admin" &&
                        <IconButton style={{ padding: 0 }} aria-label="next">
                          <IconButton
                            size="small"
                            style={{ color: "#6E00FF" }}
                            onClick={(e) => {
                              handleOpenConfirmationModal("delete", whatsApp.id);
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </IconButton>
                      }
                      {/* boton editar */}
                      {user.profile === "admin" &&
                        <IconButton aria-label="previous">
                          <IconButton
                            size="small"
                            style={{ color: "#6E00FF" }}
                            onClick={() => handleEditWhatsApp(whatsApp)}
                          >
                            <Edit />
                          </IconButton>
                        </IconButton>}
                      {/* {renderStatusToolTips(whatsApp)} */}

                      {/* esperando qr para conectat */}
                      {whatsApp.status === "qrcode" && (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: "#F3B559", marginLeft: 5 }}
                          onClick={() => handleOpenQrModal(whatsApp)}
                        >
                          <Icon style={{ fontSize: 25, color: "#FFFFF" }} >qr_code_scanner</Icon>
                        </Button>
                      )}
                      {/* boton para desconectar */}
                      {(whatsApp.status === "CONNECTED" ||
                        whatsApp.status === "PAIRING" ||
                        whatsApp.status === "TIMEOUT") && (
                          user.profile === "admin" &&
                          <Tooltip title="Desconectar línea">
                            <Button
                              size="small"
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                handleOpenConfirmationModal("disconnect", whatsApp.id);
                              }}
                            >
                              <PhonelinkEraseIcon />
                            </Button>
                          </Tooltip>
                        )}

                    </Box>
                  </Box>
                  <div style={{ display: "grid", alignItems: "start", height: 200 }}>
                    <Icon style={{ fontSize: 130, color: green[500] }}>
                      whatsapp
                    </Icon>
                    <IconButton aria-label="play/pause">
                      {renderActionButtons(whatsApp)}
                      {/* <PlayArrowIcon sx={{ height: 38, width: 38 }} /> */}
                    </IconButton>
                  </div>
                </Card>
              </Grid>
            ))}
        </Grid>

        <Table size="small">
          {/* <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("connections.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.session")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.lastUpdate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.default")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {loading ? (
              <TableRowSkeleton />
            ) : (
              <>
                {/* {whatsApps?.length > 0 &&
                  whatsApps.map((whatsApp) => (
                    <TableRow key={whatsApp.id}>
                      <TableCell align="center">{whatsApp.name}</TableCell>
                      <TableCell align="center">
                        {renderStatusToolTips(whatsApp)}
                      </TableCell>
                      <TableCell align="center">
                        {renderActionButtons(whatsApp)}
                      </TableCell>
                      <TableCell align="center">
                        {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {whatsApp.isDefault && (
                          <div className={classes.customTableCell}>
                            <CheckCircle style={{ color: green[500] }} />
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleEditWhatsApp(whatsApp)}
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            handleOpenConfirmationModal("delete", whatsApp.id);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))} */}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <MainHeader>
        {/* <Title>{i18n.t("connections.title")}</Title> */}
        <MainHeaderButtonsWrapper>
          {user.profile === "admin" &&
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenWhatsAppModal}
            >
              {i18n.t("connections.buttons.add")}
            </Button>
          }
        </MainHeaderButtonsWrapper>
      </MainHeader>
    </MainContainer>
  );
};

export default Connections;
