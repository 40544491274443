import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import './App.css';

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#3A86FF" },
        secondary: { main: "#FF006E" },
      },
      typography: {
        body2: {
          color: "#737373",
          fontFamily: "Plus Jakarta Sans",
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: "transparent",
            boxShadow: "none",
            margin: "0px",
            "&.Mui-expanded": {
              backgroundColor: "red"
            }
          },
          expanded: {
            margin: "0px",
            backgroundColor: "red"
          }
        }
      },
      MuiExpansionPanelSummary: {
             root: {
               "&$expanded": {
                 boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)",
                 backgroundColor: "red"
               }
              }
            }

    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

export default App;
