import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import SelectedContacts from "./SelectedContacts";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  dataGrid: {
    cursor: 'pointer',
    '& .MuiDataGrid-cellCheckbox': {
      display: 'none !important',
    },
    '& .MuiDataGrid-columnHeaderCheckbox': {
      display: 'none !important',
    },

  }
}));

const Contacts = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [stateSubmit, setStateSubmit] = useState('idle');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchData();
    }
  };

  useEffect(() => {
    searchData();
  }, []);

  useEffect(() => {
    if (searchParam.length === 0) {
      searchData();
    }
  }, [searchParam]);

  useEffect(() => {
    searchData();
  }, [pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (user.companyId === data?.contact?.companyId && (data.action === "update" || data.action === "create")) {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const searchData = async () => {
    try {
      dispatch({ type: "RESET" });
      setPageNumber(1);
      const { data } = await api.get("/contacts/", {
        params: { searchParam, pageNumber, companyId: user?.companyId },
      });
      dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
      setHasMore(data.hasMore);
      setLoading(false);
      setStateSubmit('success')
    } catch (err) {
      setStateSubmit('error')
      toastError(err);
    }
  }

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user?.id,
        status: "open",
      });
      history.push(`/conversations/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import", { companyId: user.companyId });
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteSelectedContact = (contact) => {
    const updatedSelectedContacts = selectedContactIds.filter(
      (c) => c.id !== contact.id
    );
    setSelectedContactIds(updatedSelectedContacts);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleRowClick = (params) => {
    const resultContacts = selectedContactIds.filter(data => data.id !== params.row.id)
    if (resultContacts.length === selectedContactIds.length) {
      setSelectedContactIds(prevSelectedContactIds => [...prevSelectedContactIds, params.row]);
    } else {
      setSelectedContactIds(resultContacts)
    }
  };

  const handleTagDelete = async (contact, itemDelete) => {
    contact.extraInfo = contact.extraInfo.filter(filter => filter.id !== itemDelete);
    try {
      await api.put(`/contacts/${contact.id}`, contact);
    } catch (err) {
      toastError(err);
    }
  };
  const rows = [];
  const setRows = (data) => {
    // console.log(data)
  }

  const columns = [
    //{field: 'id', headerName: "id"},
    { field: 'name', headerName: i18n.t("contacts.table.name"), flex: 1 },
    { field: 'whatsapp', headerName: i18n.t("contacts.table.whatsapp"), flex: 1 },
    {
      field: 'tags',
      headerName: i18n.t("contacts.table.tags"),
      renderCell: (params) => {
        return params?.value?.map((data) => (
          <Chip label={data?.value} size="small" />
        ))
      },
      flex: 1
    },
    {
      field: 'actions',
      headerName: i18n.t("contacts.table.actions"),
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              onClick={() => hadleEditContact(params.id)}
            >
              <EditIcon />
            </IconButton>

            <Can
              role={user.profile}
              perform="contacts-page:deleteContact"
              yes={() => (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    setConfirmOpen(true);
                    setDeletingContact(params);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )} />
          </div>

        )
      }
    },
  ];

  contacts.map((contact) => {
    if (contact != null) {
      rows.push(
        {
          id: contact.id,
          name: contact.name,
          whatsapp: contact.number,
          tags: contact.extraInfo?.filter(filter => filter.name === "TAG"),
          email: contact.email,
        }
      )
    }
  });

  return (
    <div style={{ margin: "25px" }}  >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MainContainer className={classes.mainContainer}>
        <ContactModal
          open={contactModalOpen}
          onClose={handleCloseContactModal}
          aria-labelledby="form-dialog-title"
          contactId={selectedContactId}
        ></ContactModal>
        <ConfirmationModal
          title={
            deletingContact
              ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
              }?`
              : `${i18n.t("contacts.confirmationModal.importTitlte")}`
          }
          open={confirmOpen}
          onClose={setConfirmOpen}
          onConfirm={(e) =>
            deletingContact
              ? handleDeleteContact(deletingContact.id)
              : handleimportContact()
          }
        >
          {deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
            : `${i18n.t("contacts.confirmationModal.importMessage")}`}
        </ConfirmationModal>
        <MainHeader>
          <Title>{i18n.t("contacts.title")}</Title>
          <MainHeaderButtonsWrapper>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              variant="outlined"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              size="small"
              InputProps={{
                endAdornment: (
                  <Button variant="contained" onClick={searchData} size="small" sx={{ height: '25px', fontSize: '11px' }}  >
                    Buscar
                  </Button>
                ),
              }}
            />
            {/* <Button
            variant="contained"
            color="primary"
            onClick={(e) => setConfirmOpen(true)}
          >
            {i18n.t("contacts.buttons.import")}
          </Button> */}

            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenContactModal}
              sx={{ mt: 1 }}
            >
              {i18n.t("contacts.buttons.add")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Box>
          <SelectedContacts
            selectedValues={selectedContactIds}
            // onChange={(newValue) => setSelectedContacts(newValue)}
            onDelete={handleDeleteSelectedContact}
          />
        </Box>
        <Paper
          className={classes.mainPaper}
          onScroll={handleScroll}
        >

          {rows && rows.length > 0 ? (
            <DataGrid
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              checkboxSelection
              rowSelectionModel={selectedContactIds.map(data => data.id)}
              onRowClick={handleRowClick}
              className={classes.dataGrid}
            // style={""}MuiCheckbox-root
            />
          ) : (
            <></>
          )}

        </Paper>
      </MainContainer>
    </div>
  );
};

export default Contacts;
