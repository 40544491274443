import React, { useEffect, useState, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import { IconButton, Paper, Tooltip } from "@mui/material";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DataGrid } from '@mui/x-data-grid';
import CampaignForm from "../../components/CampaignForm";
import api from "../../services/api";
import { PieChart } from '@mui/x-charts/PieChart';
import moment from 'moment';
import 'moment-timezone';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import openSocket from "../../services/socket-io";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import SendIcon from '@mui/icons-material/Send';
import { NoDataTable } from "../../components/nodata/nodata";
import { Stack, Button } from '@mui/material';
import CampaignDetail from "../../components/CampaignDetail";

const columns = [
  { field: 'id', headerName: 'Codigo', width: 70 },
  { field: 'name', headerName: 'Campaña', width: 300 }, // Ajusta las columnas según la respuesta de la API
  { field: 'body', headerName: 'Mensaje', width: 440 },
  {
    field: 'dateSent',
    headerName: 'Enviar el',
    width: 190,
    valueGetter: (params) => {
      const dateSent = moment(params.row.dateSent);

      if (dateSent.isSame(moment(), 'day')) {
        return `Hoy ${dateSent.format('hh:mm A')}`;
      } else if (dateSent.isSame(moment().subtract(1, 'day'), 'day')) {
        return `Ayer ${dateSent.format('hh:mm A')}`;
      } else if (dateSent.isSame(moment().add(1, 'day'), 'day')) {
        return `Mañana ${dateSent.format('hh:mm A')}`;
      } else {
        return dateSent.format('DD/MM/YYYY hh:mm A');
      }
    },
  },


  // { field: 'totalFailed', headerName: 'Fallido', width: 130 },
  {
    field: 'fin',
    headerName: 'Finaliza el',
    width: 190,
    valueGetter: (params) => {
      const minutosEntrega = params.row.totalSent / params.row.messageForMin;
      const dateSent = moment(params.row.dateSent);
      const dateEnd = dateSent.add(minutosEntrega, 'minutes');

      // Formatear la fecha final
      return dateEnd.format('DD/MM/YYYY hh:mm A');
    },
  },
  { field: 'totalSent', headerName: 'Enviar', width: 100 },
  // {
  //   field: 'totalSentChart',
  //   headerName: 'Enviados',
  //   width: 190,
  //   renderCell: (params) => (
  //     <PieChart
  //       series={[
  //         {
  //           startAngle: -90,
  //           endAngle: 90,
  //           data: [
  //             {
  //               name: 'Enviados',
  //               value: params.row.totalSent,
  //             },
  //             {
  //               name: 'Fallados',
  //               value: params.row.totalFailed,
  //             },
  //           ],
  //         },
  //       ]}
  //       height={50}
  //       legend={{ hidden: true }}
  //     />
  //   ),
  // },

  {
    field: 'createdAt',
    headerName: 'Creado',
    width: 190,
    valueGetter: (params) => {
      return moment(params.row.createdAt).format('DD/MM/YYYY hh:mm A');
    },
  },
];

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Campaigns = () => {
  const [showModalForm, setShowModalForm] = React.useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const { user } = useContext(AuthContext);
  const openModal = () => setShowModalForm(true);
  const closeModal = () => setShowModalForm(false);
  const [detalleOpen, setDetalleOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState();


  const classes = useStyles();
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/campaigns/${user?.companyId}`);
        const sortedCampaigns = response.data.rows.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setCampaignData(sortedCampaigns);
      } catch (error) {
        console.error('Error al obtener datos de la API', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const socket = openSocket();
    socket.on("campaign", (data) => {
      if (data.action === "create") {
        setCampaignData((prevCampaigns) => [data.campaign, ...prevCampaigns]);

      }
      if (data.action === "update") {

      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const onRowsSelectionHandler = (ids) => {
    handleDetalleToggle();
    setSelectedCampaignId(ids.id);
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      // await api.delete(`/campaign/${campaignId}`);
      // Actualizar la lista de campañas después de eliminar la campaña
      const updatedCampaigns = campaignData.filter((campaign) => campaign.id !== campaignId);
      setCampaignData(updatedCampaigns);
      setSelectedCampaign(null); // Desseleccionar la campaña
    } catch (error) {
      console.error('Error al eliminar la campaña', error);
    }
  };

  const handleDetalleToggle = () => {
    setDetalleOpen(!detalleOpen);
  };

  const handleCloseDetalle = () => {
    setDetalleOpen(false);
  };

  return (
    <div style={{ margin: "25px" }}>
      <CampaignDetail open={detalleOpen} onClose={handleCloseDetalle} selectedCampaignId={selectedCampaignId} />
      <MainContainer>
        <MainHeader>
          <Title>{i18n.t("campaign.title")}</Title>
          <MainHeaderButtonsWrapper>
            <Tooltip title="Crear campaña">
              <IconButton aria-label="delete" size="medium" onClick={openModal}>
                <AddCircleOutlineIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar campaña selecionada">
              <IconButton disabled={!selectedCampaign} size="medium" onClick={() => handleDeleteCampaign()}>
                <BorderColorIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Templates / Plantillas">
              <IconButton disabled size="medium" onClick={openModal}>
                <ChromeReaderModeIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>

          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper className={classes.mainPaper}>
          <div style={{ height: "calc(100vh - 100px)", width: '100%' }}>
            {campaignData.length > 0 ?
              <DataGrid
                rows={campaignData} // Utiliza los datos obtenidos de la API
                columns={columns}
                pageSizeOptions={[5, 10]}
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                onRowSelected={(ids) => onRowsSelectionHandler(ids)}
                onRowClick={onRowsSelectionHandler}
                localeText={{ noRowsLabel: 'Inicie creando su campaña' }}
              />
              :
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ height: '100%' }} >
                <NoDataTable title="Aún no inicia con campañas" />
                <Button variant="contained" endIcon={<SendIcon />} onClick={openModal} sx={{ maxWidth: '200px' }}>
                  Comience ahora
                </Button>

              </Stack>
            }
          </div>
        </Paper>
        <CampaignForm open={showModalForm} onClose={closeModal} />
      </MainContainer>
    </div>
  );
};

export default Campaigns;
