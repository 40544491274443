import React from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stack from '@mui/material/Stack';
import { makeStyles } from "@material-ui/core/styles";
import "./index.css"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BackdropLoading = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>

      <Stack
        direction="column"
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span class="loaderRocket"></span>
        <span>Cargando...</span>
      </Stack>
    </Backdrop>
  );
};

export default BackdropLoading;
