import React, { useEffect, useState, useContext } from "react";
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";
import List from '@mui/material/List';
import { Stack, Typography, Chip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import MarkdownWrapper from '../MarkdownWrapper';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const Search = ({ value, onClose }) => {
  const { user } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [searchResultsContact, setSearchResultsContact] = useState([]);
  const [searchResultsMessages, setSearchResultsMessages] = useState([]);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);
      setError(null);

      try {
        const { data } = await api.get(`/search`, {
          params: {
            query: value,
            companyId: user.companyId,
          },
        });

        setSearchResultsContact(data.contacts);
        setSearchResultsMessages(data?.messages);

      } catch (error) {
        console.error('Error al obtener los resultados de búsqueda:', error);
        toastError('Error al obtener los resultados de búsqueda');
      }

      setLoading(false);
    };

    if (value) {
      fetchSearchResults();
    }

  }, [value]);

  const formatDate = (dateTime) => {
    return moment(dateTime).format('dddd DD MMMM HH:mm'); // Formato personalizado
  };


  const handleSelect = (ticketId) => {
    history.push(`/conversations/${ticketId}`);
    onClose();
  }

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user.id,
        companyId: user.companyId,
        status: "open",
      });
      history.push(`/conversations/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
    onClose();
  };


  const LoadingSkeleton = () => {
    return (
      <Stack direction="row">
        <div>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        </div>
        <div style={{ width: '100%', paddingLeft: '10px' }}>
          <Skeleton
            animation="wave"
            height={10}
            width="40%"
            variant="rounded" height={15}
            style={{ marginBottom: 6 }}
          />
          <Skeleton animation="wave" height={10} width="80%" variant="rounded" height={15} />
        </div>
      </Stack>
    )
  }
  return (
    <Stack spacing={2} m={3} >

      <Typography variant="h3" >
        Resultado de búsqueda
      </Typography>
      <Stack justifyContent="space-between" direction="row">
        <Typography color="GrayText" variant="body2">
          Coincidencias con  "<b>{value}</b>"

        </Typography>
        <div>
          <Chip label={<div>Contactos: {searchResultsContact.length}</div>} size="small" />
          <Chip label={<div>Chats: {searchResultsMessages.length}</div>} size="small" />
        </div>
      </Stack>

      <Divider />
      <div>
        {error && <Typography variant="body1" color="error">{error}</Typography>} {/* Muestra un mensaje de error si hay un error */}

        <List>
          {searchResultsContact.map((result) => (
            <ListItem key={result.id} button onClick={() => handleSaveTicket(result.id)}>
              <ListItemAvatar>
                <Avatar alt={result.name} src={result.profilePicUrl} />
              </ListItemAvatar>
              <ListItemText primary={result.name} secondary={result.number} />
            </ListItem>
          ))}
          {searchResultsMessages.map((result) => (
            <ListItem key={result.id} button onClick={() => handleSelect(result?.ticketId)}>
              <ListItemAvatar>
                <Avatar>
                  <WhatsAppIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={
                <Stack justifyContent="space-between" direction="row">
                  <section>
                    <Chip icon={<WhatsAppIcon />} label={result?.mediaType} size="small" />
                    <Chip label={result?.fromMe ? 'Enviado' : 'Recibido'} size="small" />
                    <Chip label={formatDate(result?.createdAt)} size="small" />
                  </section>
                </Stack>
              } secondary={<MarkdownWrapper>{result?.body}</MarkdownWrapper>} />
            </ListItem>
          ))}
          {loading &&
            <Stack direction="column" spacing={2} sx={{ ml: '18px' }}>
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton />
            </Stack>
          }
        </List>
      </div >
    </Stack>
  );
};

export default Search;