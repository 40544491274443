import React from 'react';
import { Button, Typography, Stack } from '@mui/material';

const CampaignTemplate = ({ onSave, onCancel, isEdit }) => {
  return (
    <Stack spacing={1} direction='column' justifyContent="center" alignItems="center"  >
      <Typography variant="h6" sx={{ pb: 1 }}>¿Desea guardar este mensaje como plantilla para campañas futuras?</Typography>
      <Stack spacing={1} justifyContent="center">
        <Button size="small" variant="contained" color="primary" onClick={onSave} >
          Guardar {isEdit && 'como nueva'}
        </Button>
        {isEdit &&
          <Button size="small" variant="contained" color="success" onClick={() => onSave('update')}>
            Guardar actual
          </Button>
        }
        <Button size="small" variant="contained" color="secondary" onClick={onCancel}>no deseo</Button>
      </Stack>
    </Stack>
  );
};

export default CampaignTemplate;
