import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemText, Divider, Avatar, ListItemAvatar, IconButton, Typography, Paper, Stack, Grid } from '@mui/material';
import api from '../../services/api';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import PanToolIcon from '@mui/icons-material/PanTool';
import Tooltip from '@mui/material/Tooltip';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import './styles.css';

const CampaignDetail = ({ open, onClose, selectedCampaignId }) => {

  const [campaign, setCampaign] = useState(null);
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [valueSent, setValueSent] = useState(0);
  const [valueSuccess, setValueSuccess] = useState(0);
  const [valueFaild, setValueFaild] = useState(0);
  const [valuePeding, setValuePending] = useState(0);

  const fetchCampaign = async () => {
    try {
      const response = await api.get(`/campaign/${selectedCampaignId}`);
      const { data } = response;
      setCampaign(data)
    } catch (error) {
      console.error('Error fetching campaign:', error);
    }
  }
  const calculateElemnts = () => {
    if (campaignDetail?.length > 0) {
      const counts = campaignDetail?.reduce(
        (acc, campaign) => {
          if (campaign.deliveryStatus !== "pendiente") {
            acc.sent++;
          }
          if (campaign.deliveryStatus === "enviado") {
            acc.success++;
          }
          if (campaign.deliveryStatus === "fallo") {
            acc.fail++;
          }
          if (campaign.deliveryStatus === "pendiente") {
            acc.pending++;
          }
          return acc;
        },
        { sent: 0, success: 0, fail: 0, pending: 0 }
      );

      setValueSent(counts.sent);
      setValueSuccess(counts.success);
      setValueFaild(counts.fail);
      setValuePending(counts.pending);
    }
  }

  useEffect(() => {
    const fetchCampaignDetail = async () => {
      // carga de campaña
      fetchCampaign();
      // carga de detalle
      try {
        const response = await api.get(`/campaigns/details/${selectedCampaignId}`);
        setCampaignDetail(response.data.rows);
      } catch (error) {
        console.error('Error fetching campaign detail:', error);
      }
    };

    if (open && selectedCampaignId) {
      setCampaignDetail(null)
      fetchCampaignDetail();
    }
  }, [open, selectedCampaignId]);

  useEffect(() => {
    calculateElemnts();
  }, [campaignDetail]);

  const formatDate = (dateString) => {
    return moment(dateString).format('DD MMMM YYYY');
  };

  const formatTime = (dateString) => {
    return moment(dateString).format('hh:mm A');
  };



  return (
    <Drawer anchor="right" open={open} onClose={onClose}>

      <Paper elevation={4} sx={{ marginLeft: 1.7, marginTop: 1.7 }} style={{ maxWidth: '96%' }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2.5}
        >
          <Typography variant="h5" gutterBottom sx={{ paddingTop: 1.2 }}>
            {campaign?.name || "cargando..."}
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'monospace' }}>
            {valueSent}/{campaignDetail?.length} <span style={{ fontSize: 20 }}>envios</span>
          </Typography>
          <Grid container rowSpacing={1} sx={{ maxWidth: '100%', padding: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={6}>
              <div className="card-detalles-values">❌ Fallidos:
                <span>{valueFaild}</span>
              </div>
            </Grid>
            <Grid xs={6}>
              <div className="card-detalles-values">✅ Entregados: <span>{valueSuccess}</span></div>
            </Grid>
            <Grid xs={6}>
              <div className="card-detalles-values">📶 Línea: <span>+{campaign?.whatsapp?.number}</span></div>
              {/* {campaign?.whatsapp?.name} */}
            </Grid>
            <Grid xs={6}>
              <div className="card-detalles-values">⏰ Entretiempo: <span>{campaign?.messageForMin}/min</span></div>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Typography variant="h6" color="InfoText" sx={{ margin: 2 }} >
        Detalle de Campaña
      </Typography>
      <Divider />
      <List sx={{ minWidth: '460px' }}>
        {campaignDetail?.length > 0 ? (
          campaignDetail.map((campaign) => (
            <div key={campaign.id}>
              <ListItem secondaryAction={
                campaign?.deliveryStatus === "pendiente" &&
                <Tooltip title="Detener este envio">
                  <IconButton edge="end" aria-label="delete">
                    <PanToolIcon style={{ color: '#e9cbcb' }} />
                  </IconButton>
                </Tooltip>
              }>
                <ListItemAvatar>

                  {campaign?.deliveryStatus === "pendiente" &&
                    <Avatar >
                      <Tooltip title="Por enviar">
                        ☑️
                      </Tooltip>
                    </Avatar>
                  }
                  {campaign?.deliveryStatus === "enviado" &&
                    <Avatar style={{ color: 'white', backgroundColor: '#94c694' }}>
                      <Tooltip title="Enviado">
                        ✅
                      </Tooltip>
                    </Avatar>
                  }
                  {campaign?.deliveryStatus === "fallo" &&
                    <Avatar style={{ color: 'white', backgroundColor: '#F9EBEA' }}>
                      <Tooltip title="Fallo">
                        ❌
                      </Tooltip>
                    </Avatar>
                  }

                </ListItemAvatar>
                <ListItemText primary={campaign.name + ' - ' + campaign.to} secondary={`📅  ${formatDate(campaign.updatedAt)} (${formatTime(campaign.updatedAt)})`} />
                {/* Render other details from campaign */}
              </ListItem>
              <Divider />
            </div>
          ))


        ) : (
          <ListItem>
            <ListItemText primary="Loading campaign detail..." />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default CampaignDetail;
