import React from 'react';
import { Stack, Typography } from '@mui/material'; // Importa los componentes de Material-UI según sea necesario
import nodata from "./table-empty.jpeg"

// Componente para cuando no hay datos en una tabla
export const NoDataTable = ({ title }) => (
  <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
    <img src={nodata} alt="No Data" style={{ maxWidth: '300px' }} loading="lazy" />
    <Typography variant="h5">{title}</Typography>
  </Stack>
);

