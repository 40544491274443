import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import MaterialIcon, { colorPalette } from "material-icons-react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import TransferTicketModal from "../TransferTicketModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import PushPinIcon from '@mui/icons-material/PushPin';

function DialogClosedStatus(props) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { onClose, selectedValue, open, handleUpdateTicketStatus } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle
        style={{ backgroundColor: "#e0f2f1" }}
        id="simple-dialog-title"
      >
        {i18n.t("closeStatus.subtitle")}
      </DialogTitle>
      <List>
        {props?.selectedValue?.map((closedValue) => (
          <ListItem
            button
            onClick={() => {handleUpdateTicketStatus("closed", user?.id, closedValue?.id); handleListItemClick(closedValue)}}
            key={closedValue}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <BookmarkBorderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              style={{ minWidth: "200px" }}
              primary={closedValue.name}
              
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  fabProgress: {
    color: "green",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "0",
    left: "0",
    marginTop: -20,
    marginLeft: -12,
  },
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: "2px",
    },
  },
}));

const TicketActionButtons = ({ ticket, closedStatus }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const isMounted = useRef(true);
  const [open, setOpen] = React.useState(false);

  const handleClickOpenClosedStatus = () => {
    setOpen(true);
  };

  const handleCloseClosedStatus = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleOpenTransferModal = (e) => {
    setTransferTicketModalOpen(true);
    handleCloseTicketOptionsMenu();
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };
  
  const hanldePinned = async () => {
    setLoading(true);
    
    try {
      await api.put(`/tickets/${ticket.id}`, {
        pinned: ticket.pinned? false: true,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };


  const handleUpdateTicketStatus = async (status, userId, closedStatusId) => {
    setLoading(true);
    
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        closedStatusId: closedStatusId,
        userId: userId || null,
      });

      setLoading(false);
      if (status === "open") {
        history.push(`/conversations/${ticket.id}`);
      } else {
        history.push("/conversations");
      }
    } catch (err) {
      setLoading(false);
      // toastError(err);
    }
  };

  return (
    <div className={classes.actionButtons}>
      <DialogClosedStatus
        selectedValue={closedStatus}
        open={open}
        onClose={handleCloseClosedStatus}
        handleUpdateTicketStatus={handleUpdateTicketStatus}
      />

      <TransferTicketModal
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket.id}
        ticketWhatsappId={ticket.whatsappId}
      />

      {ticket.status === "closed" && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay />}
          size="small"
          onClick={(e) => handleUpdateTicketStatus ("open", user?.id, null)}
        >
          {i18n.t("messagesList.header.buttons.reopen")}
        </ButtonWithSpinner>
      )}
      {ticket.status === "open" && (
        <>
          <Tooltip title={ticket?.pinned ? "Quitar chat favorito":"Fijar chat"}>
            <IconButton
              onClick={(e) => hanldePinned()}
              aria-label="Open Notifications"
              color="#6e00ff"
            >
              <StarOutlineIcon
                size="small"
                color={ticket?.pinned?"primary":""}
              />
              {loading && (
                <CircularProgress size={48} className={classes.fabProgress} />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title={i18n.t("messagesList.header.buttons.return")}>
            <IconButton
              onClick={(e) => handleUpdateTicketStatus("pending", null, null)}
              aria-label="Open Notifications"
              color="white"
            >
              <MaterialIcon
                icon="settings_backup_restore"
                size="small"
              />
              {loading && (
                <CircularProgress size={48} className={classes.fabProgress} />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title={i18n.t("ticketOptionsMenu.transfer")}>
            <IconButton
              onClick={handleOpenTransferModal}
              aria-label="Open Notifications"
              color="inherit"
            >
              <MaterialIcon icon="call_split" size="small" />
              {loading && (
                <CircularProgress size={48} className={classes.fabProgress} />
              )}
            </IconButton>
          </Tooltip>
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClickOpenClosedStatus}
          >
            {i18n.t("messagesList.header.buttons.resolve")}
          </ButtonWithSpinner>

          <IconButton onClick={handleOpenTicketOptionsMenu}>
            <MoreVert />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={() => handleCloseTicketOptionsMenu("")}
          />
        </>
      )}
      {ticket.status === "pending" && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          // onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          onClick={(e) => handleUpdateTicketStatus("open", user?.id, null)}
        >
          {i18n.t("messagesList.header.buttons.accept")}
        </ButtonWithSpinner>
      )}
    </div>
  );
};

export default TicketActionButtons;
