import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Badge, IconButton } from "@material-ui/core";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import NotificationsPopOver from "../components/NotificationsPopOver";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import logo from "../assets/wapp.png";
import { i18n } from "../translate/i18n";
import MaterialIcon from "material-icons-react";
import UserModal from "../components/UserModal";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import CampaignIcon from '@mui/icons-material/Campaign';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import lottie from "lottie-web";

import './style.css';
import { Chip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  menuBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  iconbar: {
    paddingLeft: "0",
  },
}));

function ListItemLink(props) {
  const classes = useStyles();
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={classes.iconbar}>
        {/* {icon ? <ListItemIcon>{icon}</ListItemIcon> : null} */}
        <div className={classes.menuBar}>
          <ListItemIcon>{icon}</ListItemIcon>
          {/* <ListItemText secondary={primary}/> */}
        </div>
      </ListItem>
    </li>
  );
}

const Container = styled.div`
  position: fixed;
  .active {
    border-right: 4px solid #f3b559;
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

const Button = styled.button`
  background-color: #6e00ff;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before,
  &::after {
    content: "";
    background-color: white;
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }
  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  background-color: #6e00ff;
  width: 3.5rem;
  height: 80vh;
  margin-top: 4rem;
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Logo = styled.div`
  width: 2rem;
  img {
    width: 100%;
    height: auto;
  }
`;

const SlickBar = styled.ul`
  color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6e00ff;
  padding: 2rem 0;
  position: absolute;
  top: 6rem;
  left: 0;
  width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: white;
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  padding-left: 1rem;
  &:hover {
    border-right: 4px solid white;
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
  padding-right: 1rem;
`;

const Company = styled.div`
  width: ${(props) => (props.clicked ? "14rem" : "3rem")};
  height: 3rem;
  padding: 0.5rem 1rem;
  /* border: 2px solid var(--white); */
  border-radius: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.clicked ? "9rem" : "0")};
  background-color: #6e00ff;
  color: white;
  transition: all 0.3s ease;
  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      border: 2px solid grey;
      padding: 2px;
    }
  }
`;

const Profile = styled.div`
  width: ${(props) => (props.clicked ? "18rem" : "3rem")};
  height: 3rem;
  padding: 0.5rem 1rem;
  /* border: 2px solid var(--white); */
  border-radius: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.clicked ? "13rem" : "0")};
  background-color: #6e00ff;
  color: white;
  transition: all 0.3s ease;
  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      border: 2px solid grey;
      padding: 2px;
    }
  }
`;

const Details = styled.div`
  display: ${(props) => (props.clicked ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    display: inline-block;
  }
  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: grey;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Logout = styled.button`
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  img {
    width: 100%;
    height: auto;
    filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg)
      brightness(100%) contrast(126%);
    transition: all 0.3s ease;
    &:hover {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;

const MainListItems = (props) => {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [click, setClick] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const handleClick = () => setClick(!click);
  const { user } = useContext(AuthContext);
  const [profileClick, setprofileClick] = useState(false);
  const [profileClickCompany, setprofileClickCompany] = useState(false);
  const handleProfileClickCompany = () =>
    setprofileClickCompany(!profileClickCompany);
  const handleProfileClick = () => setprofileClick(!profileClick);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const history = useHistory();
  // Antes

  // const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const containerRef = React.useRef(null);

  const container = React.useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("./logo.json")
    });
  }, []);

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <div className="sidebar close">
        <div class="logo-details">
          <i className="container" ref={container}></i>
          <span class="logo_name">ChatUP</span>
        </div>
        <div class="logo_nametext">
          <span>chatup</span>
        </div>
        <ul className="nav-links">
          <li>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/"
            >
              <a href="#">
                <MaterialIcon icon="grid_view" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Inicio
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/conversations"
            >
              <a href="#">
                <MaterialIcon icon="forum" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Chats
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/contacts"
            >
              <a href="#">
                <MaterialIcon icon="contact_phone" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Clientes
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/campaigns"
            >
              <a href="#">
                <MaterialIcon icon="campaign" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Campañas
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/reports"
            >
              <a href="#">
                <MaterialIcon icon="insert_chart_outlined" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Reportes
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/settings"
            >
              <a href="#">
                <MaterialIcon icon="settings" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Configuración
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <Item
              onClick={handleClickLogout}
              exact
              activeClassName="active"
              to="/login"
            >
              <a href="#">
                <MaterialIcon icon="logout" size="small" color="white" />
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="#">
                    Salir
                  </a>
                </li>
              </ul>
            </Item>
          </li>

          <li>
            <a href="#" onClick={handleOpenUserModal}>
              <div className="profile-details" >
                <div className="profile-content">
                  <img src={`https://api.multiavatar.com/${user?.name}.png`} alt="profileImg" />
                </div>
                <div className="name-job">
                  <div className="profile_name">Prem Shahi</div>
                  <div className="job">Web Desginer</div>
                </div>
                <i class='bx bx-log-out' ></i>
              </div>
            </a>
          </li>
        </ul>
      </div>

    </>
  );
};

export default MainListItems;
