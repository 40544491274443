import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Hidden
} from '@material-ui/core';
import { Switch, FormControlLabel } from '@material-ui/core';


import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  // password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  // email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user"
  };

  const { user: loggedInUser } = useContext(AuthContext);

  const [user, setUser] = useState(initialState);

  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [whatsappId, setWhatsappId] = useState(false);
  const { loading, whatsApps } = useWhatsApps();
  const [selectedProfile, setSelectedProfile] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setSelectedProfile(data.profile)
        const detailsobj = typeof data?.detailsobj === 'string' ?
          JSON.parse(data?.detailsobj) :
          data?.detailsobj;

        setUser(() => {
          return { ...user, ...data, detailsobj };
        });
        const userQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(userQueueIds);
        setWhatsappId(data.whatsappId ? data.whatsappId : '');
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [userId, open]);

  const handleClose = () => {
    onClose();
    setUser(initialState);
  };

  const handleSaveUser = async values => {
    const { password, email } = values

    const userData = {
      ...values, whatsappId,
      queueIds: selectedQueueIds,
      companyId: loggedInUser.companyId,
      profile: selectedProfile,
      password: selectedProfile === 'bot' ? 'admin' : password,
      email: email || `${new Date().getTime()}@bots.chatup.pe`
    };
    try {
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
          {` ${selectedProfile}`}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, values, setValues, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                {selectedProfile == 'bot' ?
                  <>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.name")}
                        autoFocus
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        margin="dense"
                      >
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editProfile"
                          yes={() => (
                            <>
                              <InputLabel id="profile-selection-input-label">
                                {i18n.t("userModal.form.profile")}
                              </InputLabel>

                              <Field
                                as={Select}
                                label={i18n.t("userModal.form.profile")}
                                name="profile"
                                labelId="profile-selection-label"
                                id="profile-selection"
                                required
                                value={selectedProfile}
                                onChange={(event) => {
                                  const selectedValue = event.target.value;
                                  setSelectedProfile(selectedValue);
                                }}
                              >
                                <MenuItem value="admin">Administrador</MenuItem>
                                <MenuItem value="user">Usuario</MenuItem>
                                <MenuItem value="bot">Bot</MenuItem>
                              </Field>
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label="URL Api:"
                        placeholder="https://xxxxx.xx"
                        name="detailsobj.urlBot"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        focused
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label="Webhook URL"
                        placeholder="https://xxxxx.xx"
                        name="detailsobj.webhook"
                        variant="outlined"
                        margin="dense"
                        focused
                        fullWidth
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label="Variable Input"
                        name="detailsobj.variableInput"
                        placeholder="variable1, variable2"
                        variant="outlined"
                        margin="dense"
                        focused
                        fullWidth
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.detailsobj?.status || false}
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              setValues((prevValues) => {
                                const updatedDetailsObj = typeof prevValues.detailsobj === 'string' ?
                                  JSON.parse(prevValues.detailsobj) :
                                  prevValues.detailsobj;

                                return {
                                  ...prevValues,
                                  detailsobj: {
                                    ...updatedDetailsObj,
                                    status: isChecked,
                                  },
                                };
                              });
                            }}
                            color="primary"
                          />
                        }
                        label="Activar / Desactivar"
                      />
                    </div>
                  </>
                  :
                  <>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.name")}
                        autoFocus
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        margin="dense"
                      >
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editProfile"
                          yes={() => (
                            <>
                              <InputLabel id="profile-selection-input-label">
                                {i18n.t("userModal.form.profile")}
                              </InputLabel>

                              <Field
                                as={Select}
                                label={i18n.t("userModal.form.profile")}
                                name="profile"
                                labelId="profile-selection-label"
                                id="profile-selection"
                                required
                                value={selectedProfile}
                                onChange={(event) => {
                                  const selectedValue = event.target.value;
                                  setSelectedProfile(selectedValue);
                                }}
                              >
                                <MenuItem value="admin">Administrador</MenuItem>
                                <MenuItem value="user">Usuario</MenuItem>
                                <MenuItem value="bot">Bot</MenuItem>
                              </Field>
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.email")}
                        name="email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />

                      <Field
                        as={TextField}
                        name="password"
                        variant="outlined"
                        margin="dense"
                        focused
                        label={i18n.t("userModal.form.password")}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((e) => !e)}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        fullWidth
                      />

                    </div>
                  </>
                }
                <Can
                  role={loggedInUser.profile}
                  perform="user-modal:editQueues"
                  yes={() => (
                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={values => setSelectedQueueIds(values)}
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {userId
                    ? `${i18n.t("userModal.buttons.okEdit")}`
                    : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
