import React from "react";
import { Chip } from "@mui/material";

const SelectedContacts = ({ selectedValues, onChange, onDelete }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  const handleDelete = (contact) => {
    onDelete(contact);
  };

  return (
    <div>
      {selectedValues.map((contact) => (
        <Chip
          key={contact.id} // Asegúrate de tener un identificador único para cada contacto
          label={contact.name} // O cualquier propiedad que desees mostrar en el Chip
          onDelete={() => handleDelete(contact)}
          color="primary"
          variant="outlined"
          size="small"
        />
      ))}
      {/* <Autocomplete
        value={selectedValues}
        onChange={handleChange}
      /> */}
    </div>
  );
};
export default SelectedContacts;