import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import Chip from '@material-ui/core/Chip';
import { CheckCircle } from "@material-ui/icons";
import SnoozeIcon from '@mui/icons-material/Snooze';
import AlarmIcon from '@mui/icons-material/Alarm';

const useStyles = makeStyles(theme => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  DividerList: {
    // maxWidth: "300px"
  },

  lastMessageTime: {
    justifySelf: "flex-end",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 70,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    top: '30px',
    right: '40px',
    fontSize: "0.7rem"
  },

  ticketQueueColor: {
    flex: "none",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
    writingMode: "vertical-rl",
    fontSize: "0.6rem",
    lineHeight: "1",
    display: "flex",
    alignContent: "flex-start",
    justifyContent: "center",
  },

  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 5,
    bottom: 5,
    color: "rgb(104, 121, 146)",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.7em"
  },
}));

const TicketListItem = ({ ticket, event, status, onlyPinned }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAcepptTicket = async ticket => {
    history.push(`/conversations/${ticket.id}`);
    setLoading(true);
    // try {
    //   console.log("ENtra aqui, Pa q");
    //   const { data } = await api.put(`/contacts/${ticket.contactId}`, { companyId: user?.companyId });
    // } catch (err) {
    //   toastError(err);
    // }
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
  };

  const handleSelectTicket = id => {
    history.push(`/conversations/${id}`);
  };

  const updatedAtDate = parseISO(ticket.updatedAt);
  const timeDifferenceInMilliseconds = Math.abs(new Date() - updatedAtDate);
  const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);

  return (
    <React.Fragment key={ticket.id}>
      <ListItem
        dense
        button
        onClick={e => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket.id);
          // event(status);

        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          > {ticket?.whatsapp?.name?.slice(0, 11)}
          </span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper} >
              <Typography
                noWrap
                component="span"
                variant="body2"
                style={{ fontFamily: 'Plus Jakarta Sans', fontSize: 16, fontWeight: '500', lineHeight: '24px' }}
              >
                {ticket.contact?.name}
              </Typography>
              {ticket.status === "closed" && (
                <Badge
                  className={classes.closedBadge}
                  badgeContent={"cerrado"}
                  color="primary"
                />
              )}
              {ticket.lastMessage && (
                <Typography
                  className={classes.lastMessageTime}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                  size="6"
                  style={{ fontFamily: 'Plus Jakarta Sans', fontSize: 10, lineHeight: '16px', fontWeight: '400' }}
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>
                      {timeDifferenceInHours > 4 ? (
                        <>
                          {format(parseISO(ticket.updatedAt), "HH:mm")}
                          {ticket.status === 'pending' &&
                            <Tooltip title="Urge atenderlo; LLeva mucho tiempo en espera">
                              <SnoozeIcon style={{ color: 'red', fontSize: '0.9rem' }} />
                            </Tooltip>
                          }
                        </>
                      ) : timeDifferenceInHours > 2 ? (
                        <>
                          {format(parseISO(ticket.updatedAt), "HH:mm")}
                          {ticket.status === 'pending' &&
                            <Tooltip title="Requiere atención, esta en espera larga">
                              <SnoozeIcon style={{ color: 'orange', fontSize: '0.9rem' }} />
                            </Tooltip>
                          }
                        </>
                      ) : (
                        <>
                          {format(parseISO(ticket.updatedAt), "HH:mm")}
                          {ticket.status === 'pending' &&
                            <Tooltip title="En espera moderada">
                              <AlarmIcon style={{ color: 'green', fontSize: '0.9rem' }} />
                            </Tooltip>
                          }
                        </>
                      )}
                    </>
                  ) : <>
                    {format(parseISO(ticket.updatedAt), "dd/MMM HH:mm")}
                    {ticket.status === 'pending' &&
                      <Tooltip title="Requiere atención, esta en espera larga">
                        <SnoozeIcon style={{ color: 'red', fontSize: '0.9rem' }} />
                      </Tooltip>
                    }
                  </>}
                </Typography>
              )}
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: 'Plus Jakarta Sans', fontSize: 12, fontWeight: '400', lineHeight: '16px' }}
              >
                {ticket.lastMessage ? (
                  <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                ) : (
                  <br />
                )}
              </Typography>

              <Badge
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }
        />
        {ticket.status === "pending" && (
          <Chip
            icon={<CheckCircle />}
            label={i18n.t("ticketsList.buttons.accept")}
            clickable
            color="primary"
            onClick={e => {
              handleAcepptTicket(ticket);
              // event(status)
            }}
            size="small"


            className={classes.acceptButton}
          />
        )}
      </ListItem>
      <Divider variant="inset" component="li" className={classes.DividerList} />
    </React.Fragment>
  );
};

export default TicketListItem;
