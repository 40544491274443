import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Reports = () => {
  const { user } = useContext(AuthContext);
  const [lastContract, setLastContract] = useState();
  const [open, setOpen] = React.useState(false);
  const loadingClose = () => {
    setOpen(false);
  };
  const loadingOpen = () => {
    setOpen(true);
  };

  const featchCompany = async () => {
    loadingOpen();
    if (user?.companyId) {
      const { companyId } = user;
      try {
        const { data } = await api.get(`/company/${companyId}`);
        setLastContract(data.lastContract);
        setTimeout(() => {
          loadingClose();
        }, 4000);
      } catch (err) {
        toastError(err);
      }
    }
  }
  useEffect(() => {
    featchCompany();
    const resizeIframe = () => {
      const iframe = document.getElementById('lookerIframe');
      if (iframe) {
        iframe.style.height = `${window.innerHeight}px`;
        iframe.style.width = '100%';
      }
    };

    window.addEventListener('resize', resizeIframe);

    resizeIframe();

    return () => {
      window.removeEventListener('resize', resizeIframe);
    };
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {lastContract &&
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            id="lookerIframe"
            title="Looker Embed"
            src={lastContract}
            frameBorder="0"
            style={{ border: 'none', width: '100%', height: '100%' }}
          ></iframe>
        </div>
      }
    </>
  );
};

export default Reports;
