import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import openSocket from "../../services/socket-io";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import "./TicketCss.css";

import { green } from "@material-ui/core/colors";
import AreaCard from "./AreaCard";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: "0",
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#f0f2f5",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },
  tabRoot: {
    backgroundColor: "#FFFFFF",
    textTransform: "lowercase",
    color: "#5A6A9D",
    size: "12px",
    borderRadius: "12px 12px 12px 12px",
    height: "48px",
    fontFamily: "SF Pro Text,SF Pro Icons,system,-apple-system,system-ui,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,Lucida Grande,Kohinoor Devanagari,sans-serif",
  },
  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F5FAFC",
    // padding: theme.spacing(1),
    paddingRight: "6px;",
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
    padding: "0 12px",
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    textTransform: "capitalize",
    fontFamily: "Plus Jakarta Sans",
    size: 12,
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  margin: {
    position: "absolute",
    zIndex: "1000",
    right: "3%",
    top: "83%",
    backgroundColor: "#53BF9D",
    "&:hover": {
      backgroundColor: green[500],
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const [invisibleNew, setinvisibleNew] = useState(true);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const [showOpen, setshowOpen] = useState(false);
  const [showPending, setshowPending] = useState(true);
  const [showClosed, setshowClosed] = useState(false);
  const [idOpen, setidOpen] = useState(2);
  const [idPending, setidPending] = useState(1);
  const [idClosed, setidClosed] = useState(3);
  const [showSearch, setShowSearch] = useState(false)
  const [messagesListType, setMessagesListType] = useState("pending")


  const handlesShowAllTickets = () => {
    setShowAllTickets(!showAllTickets)
  }
  useEffect(() => {
    if (showSearch) {
      searchInputRef.current.focus();
    }
  }, [showSearch]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();
    setMessagesListType("search")
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const socket = openSocket();

  socket.on("connect", () => socket.emit("joinNotification"));
  socket.on("appMessage", (data) => {
    if (data.ticket.status === "pending") {
      setinvisibleNew(false);
    }
  });

  return (
    <div
      style={{ padding: "0 21px 0 21px", backgroundColor: "#F5FAFC" }}
      elevation={0}
      variant="outlined"
      className={classes.ticketsWrapper}
    >
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <AreaCard
          showAllTickets={showAllTickets}
          setShowAllTickets={handlesShowAllTickets}
          setSelectedQueueIds={setSelectedQueueIds}
          selectedQueueIds={selectedQueueIds}
          setShowSearch={setShowSearch}
          handleSearch={handleSearch}
          showSearch={showSearch} />
      </div>
      {/* Buscador de chats o contacto */}
      <div hidden={!showSearch} style={{ padding: "0 0 24px 0" }}>
        <div
          style={{
            border: "1px solid #D1E4E8",
            borderRadius: "24px",
            height: "48px",
          }}
          className={classes.serachInputWrapper}
        >
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onChange={handleSearch}
          />
        </div>
        {searchParam.length > 0 && (
          <div id="categorie5.1-0">
            Resultados de busqueda:
            <TicketsList
              status="closed"
              searchParam={searchParam}
              showAll={true}
              semiShow={true}
              selectedQueueIds={selectedQueueIds}
            />
          </div>
        )}
      </div>

      {!showSearch &&
        <>
          <ButtonGroup
            color="primary"
            aria-label="Selecciones su filtro ideal"
            size="medium"
            borderRadius="24"
            variant="outlined"
          >
            <Button
              onClick={() => { setMessagesListType("pinned") }}
              variant={messagesListType === "pinned" && "contained"}
            >
              <StarOutlineIcon size="" /> <Badge className={classes.badge} color="secondary"></Badge>
            </Button>
            <Button
              onClick={() => {
                // sortcategories("open");
                setMessagesListType("open")
              }}
              variant={messagesListType === "open" && "contained"}
            >
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="secondary"
              >
                {i18n.t("tickets.tabs.open.title")}
              </Badge>
            </Button>
            <Button
              onClick={() => {
                // sortcategories("pending");
                setMessagesListType("pending")
              }}
              variant={messagesListType === "pending" && "contained"}
            >
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("tickets.tabs.search.title")}
              </Badge>
            </Button>
            <Button
              onClick={() => {
                // sortcategories("pending");
                setMessagesListType("closed")
              }}
              variant={messagesListType === "closed" && "contained"}
            >
              <Badge
                className={classes.badge}
                color="secondary"
              >
                cerrados
              </Badge>
            </Button>

          </ButtonGroup>

          <div style={{ overflow: 'auto', display: messagesListType === 'pending' ? 'block' : 'none' }}>
            <TicketsList
              status="pending"
              showAll={true}
              semiShow={showPending}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setPendingCount(val)}
            />
          </div>
          <div style={{ overflow: 'auto', display: messagesListType === 'open' ? 'block' : 'none' }}>
            <TicketsList
              status="open"
              showAll={showAllTickets}
              semiShow={showOpen}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setOpenCount(val)}
            />
          </div>
          {messagesListType === "closed" &&
            <TicketsList
              status="closed"
              showAll={true}
              semiShow={showClosed}
              selectedQueueIds={selectedQueueIds}
            />
          }
          <div style={{ overflow: 'auto', display: messagesListType === 'pinned' ? 'block' : 'none' }}>
            <TicketsList
              status="pinned"
              showAll={showAllTickets}
              selectedQueueIds={selectedQueueIds}
              onlyPinned={true}
            />
          </div>
        </>
      }

    </div>
  );
};

export default TicketsManager;
